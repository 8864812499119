<template>
  <div>
    <div class="row results">
      <div class="card col-sm-12" v-if="postcode.length < 3">
        <h3 class="text-center" style="margin-top:5%; color:#000;">Enter your postcode above to see local dealer listings here</h3>
      </div>
      <div class="card col-sm-3 col-xs-12" v-for="item in results" :key="item.id">
        <div>
          <label>
            <h3><a>{{ item.name }}</a></h3>
            <p><i class="fa fa-map-marker"></i> {{ item.address }}<br>
            {{ item.state | uppercase }} {{ item.postcode }}<br></p>
            <p><i class="fa fa-phone"></i>{{ item.phone }}</p>
            <input type="radio" name="input_34"> <span class="btn btn-lg btn-black text-uppercase pull-left" for="offer6">Select Dealer <i class="fa fa-caret-right"></i></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import EventBus from '../../event_bus'
  import dealer from '../../mixins/dealer'
  export default {
    props: ['name', 'id', 'required'],
    mixins: [dealer],
    data() {
      return this.$store.state.DealerCardPickerStore
    },
    mounted() {
      EventBus.$on('postcode_change', postcode => this.postcode = postcode)
      this.$store.dispatch('DealerCardPickerStore/index')
      console.log('Dealer card picker: mounted')
    },
    computed: {
      results() {
        let data = this.dealers.filter(dealer => {
          if (this.postcode && this.postcode.length > 2) {
            return this.isMatchPostcode(dealer, this.postcode)
          }
          return false
        })

        if(data.length < 1) {
          if (this.postcode && this.postcode.length > 2) {
            return this.dealers
          }
        } else {
          return data
        }
      }
    },
    beforeDestroy() {
      EventBus.$off('postcode_change')
      this.postcode = ''
      console.log('Dealer card picker: before destroy')
    }
  }
</script>
