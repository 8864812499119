import axios from 'axios'

const DealerStore = {
  namespaced: true,
  state: {
    dealers: [],
    mainContent: '',
    center: { lat: -25.274398, lng: 133.77513599999997 },
    zoom : 5,
    selected : null,
    state : '',
    postcode : '',
    states: ["ACT", "NSW", "NT", "SA", "TAS", "QLD", "VIC", "WA", "NZ", "PNG"]
  },
  mutations: {
    many(state, data) {
      state.dealers = data.dealers
      return state
    }
  },
  actions: {
    index(context, query) {
      axios
        .get('/api/dealers')
        .then(response => context.commit('many', response.data))
    }
  }
}

export default DealerStore
