<template>
  <header v-html="mainContent">
  </header>
</template>

<script>
  import Vue from 'vue/dist/vue.runtime'
  import axios from 'axios'

  export default {
    data() {
      return {
        mainContent: ''
      }
    },
    mounted() {
      const self = this

      axios.get('/api/header').then(response => {
        this.mainContent = response.data
        Vue.nextTick(function () {
          self.bindEvent()
        })
      })
    },
    methods: {
      bindEvent() {
        $('.has-sub-menu').mouseover(function() {
          $(this).addClass('sub-menu-open')
        }).mouseout(function() {
          $(this).removeClass('sub-menu-open')
        })
        $('.has-sub-menu a').click(function() {
          $(this).parents('.has-sub-menu').removeClass('sub-menu-open')
        })
        $('#navbar-main a').click(function() {
          $('#navbar-main').collapse('hide');
        })
      }
    }
  }
</script>
