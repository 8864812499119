<template>
  <footer id="bottom" class="site-footer" v-html="mainContent">
    
  </footer>
</template>

<script>
  import Vue from 'vue/dist/vue.runtime'
  import axios from 'axios'

  export default {
    data() {
      return {
        mainContent: ''
      }
    },
    mounted() {
      axios.get('/api/footer').then(response => {
          this.mainContent = response.data
        })
    }
  }
</script>
