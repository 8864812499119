<template>
  <div v-html="mainContent"></div>
</template>

<script>
  import axios from 'axios'

  export default {
    props: ['q', 'page'],
    data() {
      return this.$store.state.SearchStore
    },
    mounted() {
      this.currentPage = parseInt(this.page || 1)
      axios
        .get(`/api/search?q=${this.q}&per_page=${this.perPage}&page=${this.currentPage}`)
        .then(response => this.mainContent = response.data.content)
    },
    methods: {
      goToPage(pageNum) {
        this.$router.push(`/search?q=${this.q}&page=${pageNum}`)
      }
    }
  }
</script>
