import Vue from 'vue/dist/vue.runtime'
import {DataTable} from 'simple-datatables'

const afterBindScript = {
  methods: {
    runScript() {
      const self = this

      Vue.nextTick(function () {
        if($('.slickcarousel').length > 0) {
          $('.slickcarousel').slick({
              adaptiveHeight: true,
              arrows: true,
              dots: true,
              autoplay: true,
              autoplaySpeed: 3000,
              fade: true
          })
        }

        if($('.addressfinder-address-1').length > 0) {
          self.generateAddressFinder();
          self.processByMailBox();
        }

        if($('.datatable-js').length > 0) {
          self.generateDataTable($('.datatable-js').toArray());
        }

        if($('hubspots-form').length > 0) {
          self.generateHubspotsForm($('hubspots-form'));
        }

        $('.internal-link').off('click');
        $('.internal-link').click(function() {
            self.$router.push($(this).attr('href'))
            return false
        })
        if($('#kiosk-nav-tab > a').length > 0) {
          $('#kiosk-nav-tab > a').click(function(e){
            self.setActive(e);
          })
        }

        self.addCheckedEvent();
        
        $('#search_product_cat').change(function() {        
          var valSearchCat = $(this).find('option:selected').text();
          $('.select-category-value .value-text').text(valSearchCat);
        });

        var $selectors = $('select.select2');
        $.each($selectors, function(i, item){
          var placeholder = $(item).data('placeholder');
          $(item).select2({
            allowClear: true,
            dropdownAutoWidth: true,
            width: '100%',
            placeholder: placeholder ? placeholder : '',
          });
        });

        self.initProductSliders();

        if (window.innerWidth <= 575) {
          var $sm6 = $("div.col-sm-6");
          $.each($sm6, function (i, item) {
            $(item).attr("style", "width: 100%;");
          });
    
          var $sm12 = $("div.col-sm-12");
          $.each($sm12, function (i, item) {
            $(item).attr("style", "width: 100%;");
          });

          let classValues = $('#collapseCategory').attr('class');
          if (classValues !== undefined && classValues !== null) {
            classValues = classValues.replace('in', '');
            $('#collapseCategory').attr('class', classValues);
          }
                    
          $('#collapseExpandSideBar').attr('class', 'link-collapse collapsed');
          $('#collapseExpandSideBar').attr('aria-expanded', false);
        }

        $('#productBtnSearch').click(function() {
          let category_id = $('#search_product_cat').val()
          let keyword = $('#productTextSearch').val()
          if (keyword.length !== 0) {
            let url = "/products/search/" + category_id + "/results?keyword=" + keyword
            window.location.href = url
          } else if (keyword.length == 0 && category_id !== "0") {
            let url = "/category/" + category_id + "/products"
            window.location.href = url
          }
        })

        $('#productTextSearch').keypress(function(event) {
          if (event.key === "Enter") {
            let category_id = $('#search_product_cat').val()
            let keyword = $('#productTextSearch').val()
            if (keyword.length !== 0) {
              let url = "/products/search/" + category_id + "/results?keyword=" + keyword
              window.location.href = url
            } else if (keyword.length == 0 && category_id !== "0") {
              let url = "/category/" + category_id + "/products"
              window.location.href = url
            }
            return;
          }
        })

        $('#btnAdvancedSearchProduct').click(function(){
          let part_number = $('#partNumberText').val()
          let keyword = $('#keywordText').val()
          let category_id = $('#homeProductSelect').val()
          if (part_number.length == 0 && keyword.length == 0 && category_id == "0") {
            return;
          } else if (part_number.length == 0 && keyword.length == 0 && category_id !== "0") {            
            let url = "/category/" + category_id + "/products"
            window.location.href = url
          } else {
            let url = "/products/search/" + category_id + "/results?keyword=" + keyword + '&part_number=' + part_number
            window.location.href = url
          }
        })

        var eleId = '#product-featured-image';
        $(eleId).wrap('<span style="display:inline-block"></span>')
            .css('display', 'block').parent().zoom({touch: true, magnify: 0.5});

        self.initViewByButtons();
        self.initFlexSlidersInProductDetail();

        self.perPageChange();

        $('#back_to_top').click(function(){
          $('html,body').animate({ scrollTop: 0 }, 'slow')
        })

        self.sortChange();

        self.addCheckedEvent();

        var rs = $('#rev_slider_3_1_wrapper')
        if (rs.length != 0) {
          self.initRsSlider();          
        }        
      })      
    },
    generateAndGotoUrl(sort, per_page) {
      let path = "";
      if (sort !== null && per_page !== null) {
        path = window.location.pathname + '?sort=' + sort + "&per_page=" + per_page;        
      } else if (sort !== null && per_page === null) {
        path = window.location.pathname + '?sort=' + sort;        
      } else {
        path = window.location.pathname + '?per_page=' + per_page        
      }
      window.location.href = path;
    },
    setActive(event) {
      $('#kiosk-nav-tab .nav-link').removeClass('active')
      $(event.target).addClass('active')
    },
    processByMailBox() {
      const byMailBox = $('.by-mail input')
      const byMail0 = $('input.field-by-mail-0')
      const byMail1 = $('input.field-by-mail-1')
      byMailBox.change(function() {
        if(this.checked) {
          byMail0.val(0)
          byMail1.val('Website')
        } else {
          byMail0.val(1)
          byMail1.val('')
        }
      });
    },
    generateAddressFinder() {
      const script = document.createElement("script")
      const address1Input = $('.addressfinder-address-1 input')
      const address2Input = $('.addressfinder-address-2 input')
      const addressInput = $('input.addressfinder-street')
      const zipInput = $('.addressfinder-zip input')
      const stateInput = $('.addressfinder-state input')
      const cityInput = $('.addressfinder-city input')
      script.setAttribute(
        "src",
        "https://api.addressfinder.io/assets/v3/widget.js"
      );
      script.setAttribute("async", true)
      script.onload = () => {
        this.widget = new window.AddressFinder.Widget(
          address1Input[0],
          "QDMYLTVWE9F6JRKAGC4N",
          "AU",
          {
            address_params: {}
          }
        );
        this.widget.on("result:select", (fullAddress, metaData) => {
          zipInput.val(metaData.postcode)
          stateInput.val(metaData.state_territory)
          cityInput.val(metaData.locality_name)
          address1Input.val(metaData.address_line_1)
          const addressLine2 = metaData.address_line_2 === null ? '' : metaData.address_line_2
          address2Input.val(addressLine2)
          addressInput.val(metaData.address_line_1 + "\n" + addressLine2)
        });
      };
      address2Input.change(function() {
        addressInput.val(address1Input.val() + "\n" + $(this).val());
      })
      document.head.appendChild(script);
    },
    generateHubspotsForm(form) {
      const self = this
      const script = document.createElement("script")
      script.src = "https://js.hsforms.net/forms/v2.js"
      document.body.appendChild(script)

      var newForm = $('<div id="hubspotForm"></div>')
      $(form).replaceWith(newForm)
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            portalId: $(form).attr('portal-id'),
            formId: $(form).attr('form-id'),
            target: '#hubspotForm'
          })
        }
      })
    },
    generateDataTable(tables) {
      const self = this

      $.each(tables, function(idx, tbl) {
        var currentItem = this
        var title = $(tbl).data('title')
        var csvUrl = $(tbl).data('url')
        self.$papa.parse(csvUrl, {
          download: true,
          complete: function(results, file) {
            var htmlTable = $('<table></table>')
            var header = results.data[0]
            results.data.shift()
            var newData = {
              headings: header,
              data: results.data
            };
            $(currentItem).replaceWith(htmlTable)
            var dataTable = new DataTable($(htmlTable)[0]);
            dataTable.insert(newData);
          }
        })
      })      
    },
    addCheckedEvent() {
      let span = $('span.checkmark');
      let wrapper = $('label.container-checkbox')[0];
      let input = $(wrapper).find('input')[0];
      if (input != undefined) {
        if ($(input).is(":checked") == true) {
          $(span).attr('class', 'checkmark checkmark-checked')
        }            
        else{
          $(span).attr('class', 'checkmark')
        }

        $(input).change(function() {          
          if ($(input).is(":checked") == true) {
            $(span).attr('class', 'checkmark checkmark-checked')
          }            
          else{
            $(span).attr('class', 'checkmark')
          }          
        })        
      }      
    },
    initProductSliders() {
      $('.products-grid .owl-carousel').owlCarousel({        
        loop:true,
        lazyLoad: true,
        margin:20,
        autoplay:false,
        navText: ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
        dots: false,
        responsiveClass:true,
        nav:true,
        responsive:{
          0:{
            items:1,            
          },
          600:{
            items:2,            
          },
          900:{
            items:3,            
          },
          1200:{
            items:4,            
          }
        }
      });
    },
    initViewByButtons() {
      var $products_category = $('#products_category');
      var pps = $('.privilege-price');

      if ($products_category.length > 0){
        $('.toolbar a.view-grid').click(function() {
          $('.toolbar .view-by a').removeClass('active');
          $('.toolbar a.view-grid').addClass('active');
          $products_category.removeClass('view-list');

          for(let i=0; i<pps.length; i++) {
            $(pps[i]).attr('style', '');
          }
        });
    
        $('.toolbar a.view-list').click(function() {
          $('.toolbar .view-by a').removeClass('active');
          $('.toolbar a.view-list').addClass('active');
    
          if (!$products_category.hasClass('view-list')) {
            $products_category.addClass('view-list');            
          }

          for(let i=0; i<pps.length; i++) {
            $(pps[i]).attr('style', 'top: 90px;');
          }
        });
      }    
    },
    initFlexSlidersInProductDetail() {
      if ($('#carousel').length > 0 && $('#slider').length > 0){
        $('.product-thumbnail #carousel').flexslider({
          animation: "slide",
          controlNav: false,
          animationLoop: false,
          slideshow: false,
          itemWidth: 70,
          itemMargin: 10,
          asNavFor: '#slider',
          prevText: "<i class='fa fa-chevron-left'></i>",
          nextText: "<i class='fa fa-chevron-right'></i>",
        });
    
        $('.product-thumbnail #slider').flexslider({
          animation: "slide",
          controlNav: true,
          animationLoop: false,
          slideshow: false,
          sync: "#carousel",
          prevText: "<i class='fa fa-chevron-left'></i>",
          nextText: "<i class='fa fa-chevron-right'></i>"
        });
        $('#slider .slides li').zoom();
      }
    },
    perPageChange() {
      const self = this;

      const perPageBottom = $('#amount_product_bottom');
      const perPageTop = $('#amount_product_top');
      perPageBottom.change(function() {
        var optionSelected = $(this).find("option:selected");
        var valueSelected  = optionSelected.val();
        var textSelected   = optionSelected.text();
        let params = (new URL(window.location)).searchParams;        
        let sort = params.get('sort');        
        self.generateAndGotoUrl(sort, valueSelected)      
      })

      perPageTop.change(function() {
        var optionSelected = $(this).find("option:selected");
        var valueSelected  = optionSelected.val();
        var textSelected   = optionSelected.text();
        let params = (new URL(window.location)).searchParams;        
        let sort = params.get('sort');        
        self.generateAndGotoUrl(sort, valueSelected);
      })
    },
    sortChange() {    
      const self = this  
      const orderTop = $('#order_product_top');
      const orderBpottom = $('#order_product_bottom');

      orderTop.change(function() {
        var optionSelected = $(this).find("option:selected");
        var valueSelected  = optionSelected.val();        
        let params = (new URL(window.location)).searchParams;
        let per_page = params.get("per_page");
        self.generateAndGotoUrl(valueSelected, per_page);
      })

      orderBpottom.change(function() {
        var optionSelected = $(this).find("option:selected");
        var valueSelected  = optionSelected.val();        
        let params = (new URL(window.location)).searchParams;
        let per_page = params.get("per_page");
        self.generateAndGotoUrl(valueSelected, per_page);
      })
    },
    initRsSlider() {      
      if (window.RS_MODULES === undefined) window.RS_MODULES = {};
      if (RS_MODULES.modules === undefined) RS_MODULES.modules = {};
      RS_MODULES.modules["revslider31"] = {
        once:
          RS_MODULES.modules["revslider31"] !== undefined
            ? RS_MODULES.modules["revslider31"].once
            : undefined,
        init: function () {
          window.revapi3 =
            window.revapi3 === undefined ||
            window.revapi3 === null ||
            window.revapi3.length === 0
              ? document.getElementById("rev_slider_3_1")
              : window.revapi3;
          if (
            window.revapi3 === null ||
            window.revapi3 === undefined ||
            window.revapi3.length == 0
          ) {
            window.revapi3initTry =
              window.revapi3initTry === undefined ? 0 : window.revapi3initTry + 1;
            if (window.revapi3initTry < 20)
              requestAnimationFrame(function () {
                RS_MODULES.modules["revslider31"].init();
              });
            return;
          }
          window.revapi3 = jQuery(window.revapi3);
          if (window.revapi3.revolution == undefined) {
            revslider_showDoubleJqueryError("rev_slider_3_1");
            return;
          }
          revapi3.revolutionInit({
            revapi: "revapi3",
            DPR: "dpr",
            sliderLayout: "fullwidth",
            visibilityLevels: "1240,1024,778,480",
            gridwidth: "1240,1024,778,480",
            gridheight: "900,768,960,720",
            perspective: 600,
            perspectiveType: "global",
            editorheight: "900,767.988,960,720",
            responsiveLevels: "1240,1024,778,480",
            startWithSlide: 1,
            progressBar: { disableProgressBar: true },
            navigation: {
              wheelCallDelay: 1000,
              onHoverStop: false,
              bullets: {
                enable: true,
                tmp: "",
                style: "hesperiden",
              },
            },
            fanim: {
              in: { o: 0 },
              out: { a: false },
              speed: 300,
            },
            viewPort: {
              global: false,
              globalDist: "-200px",
              enable: false,
            },
            fallbacks: {
              allowHTML5AutoPlayOnAndroid: true,
            },
          });
        },
      }; // End of RevInitScript      
      if (window.RS_MODULES.checkMinimal !== undefined) {
        window.RS_MODULES.checkMinimal();
      }
    }
  }
}

export default afterBindScript